import LogWhiteLong from "../Images/log-white-logo.svg";

export default function Footer() {
	return (
		<div>
			<div className="bg-gray-1 w-full px-4">
				<div className="max-w-[1300px] mx-auto pt-16 pb-8 flex flex-col md:flex-row gap-10 justify-between">
					<div className="w-full mb-auto">
						<div className="flex flex-col gap-4 max-w-[400px] mx-auto md:mx-0">
							<a
								href="https://www.logarithmicsolutions.com/"
								className="h-full block py-2 max-w-[230px] mx-auto md:mx-0"
								target="_blank"
								rel="noreferrer"
							>
								<img src={LogWhiteLong} className="w-[60vw]" alt="logo" />
							</a>
							<p className="text-white font-raleway leading-5 text-center md:text-left">
								LogDMS is a Marketing Automation consulting agency specialized
								in providing solutions on leading marketing automation
								technology systems including Eloqua, Salesforce, Marketo and
								Hubspot.
							</p>
							<a
								href="mailto:office@logdms.com"
								className="flex flex-row gap-2 items-center group justify-center md:justify-start"
							>
								<svg
									className="fill-current text-white group-hover:text-green-11 transition-all duration-500"
									viewBox="0 0 24 24"
									width="20"
									height="20"
								>
									<path d="M19,1H5A5.006,5.006,0,0,0,0,6V18a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V6A5.006,5.006,0,0,0,19,1ZM5,3H19a3,3,0,0,1,2.78,1.887l-7.658,7.659a3.007,3.007,0,0,1-4.244,0L2.22,4.887A3,3,0,0,1,5,3ZM19,21H5a3,3,0,0,1-3-3V7.5L8.464,13.96a5.007,5.007,0,0,0,7.072,0L22,7.5V18A3,3,0,0,1,19,21Z"></path>
								</svg>
								<p className="text-white font-poppins font-bold group-hover:text-green-11 transition-all duration-500">
									office@logdms.com
								</p>
							</a>
						</div>
					</div>
					<div className="w-full flex flex-col gap-4 mb-auto">
						<div className="flex flex-col gap-1">
							<a
								href="https://www.logarithmicsolutions.com/privacy-policy"
								className="font-poppins text-green-11 text-center md:mr-auto hover:animate-pulse"
							>
								Privacy policy
							</a>
							<a
								href="https://www.logarithmicsolutions.com/terms-of-service"
								className="font-poppins text-green-11 text-center md:mr-auto hover:animate-pulse"
							>
								Terms and conditions
							</a>
							<div className="font-poppins text-green-11 text-center md:mr-auto hover:animate-pulse cursor-pointer">
								Cookies
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-gray-5 w-full px-4">
				<div className="max-w-[1300px] mx-auto py-5 md:py-0 flex flex-col md:flex-row justify-center items-center md:justify-between gap-5">
					<div className="flex items-center">
						<p className="font-raleway text-white text-sm text-center md:text-left">
							Copyright {new Date().getFullYear()} ©{" "}
							<br className="block md:hidden" />
							<span className="text-[#8f8f8f]">
								Logarithmic Solutions - All rights reserved
							</span>
						</p>
					</div>
					<div className="flex flex-row">
						<div className="border-gray-6 hover:bg-[#3b5998] transition-all duration-500 md:border-l-2 flex">
							<a
								href="https://www.facebook.com/LogarithmicSolutions/"
								target="_blank"
								rel="noreferrer"
								className="h-14 w-14 flex justify-center items-center"
							>
								<svg
									className="fill-current text-white"
									width="16"
									height="16"
									viewBox="0 0 24 24"
								>
									<path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
								</svg>
							</a>
						</div>
						<div className="border-gray-6 hover:bg-[#007bb5] transition-all duration-500 md:border-l-2 flex">
							<a
								href="https://www.linkedin.com/company/logarithmic-solutions/"
								target="_blank"
								rel="noreferrer"
								className="h-14 w-14 flex justify-center items-center"
							>
								<svg
									className="fill-current text-white"
									width="16"
									height="16"
									viewBox="0 0 24 24"
								>
									<path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"></path>
								</svg>
							</a>
						</div>
						<div className="border-gray-6 hover:bg-[#00aced] transition-all duration-500 md:border-l-2 flex">
							<a
								href="https://twitter.com/LogDMS"
								target="_blank"
								rel="noreferrer"
								className="h-14 w-14 flex justify-center items-center"
							>
								<svg
									className="fill-current text-white"
									width="16"
									height="16"
									viewBox="0 0 24 24"
								>
									<path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
								</svg>
							</a>
						</div>
						<div className="border-gray-6 hover:bg-green-11 transition-all duration-500 md:border-x-2 flex">
							<a
								href="mailto:office@logdms.com"
								className="h-14 w-14 flex justify-center items-center"
							>
								<svg
									className="fill-current text-white"
									width="16"
									height="16"
									viewBox="0 0 24 24"
								>
									<path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z"></path>
								</svg>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
