import React, { useEffect, useState } from "react"
import { Select, message } from "antd"

const MainScreen = ({ data }) => {
    const [appData, changeData] = useState({});
    const [fields, setFields] = useState([]);
    const [store, setStore] = useState(null);

    const [sourceMapping, setSourceMapping] = useState([]);
    const [destinationMapping, setDestinationMapping] = useState([]);

    const [destinationCDOData, changeDestinationCDOData] = useState(null);
    const [disableBtn, setDisableBtn] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        console.log(data.jwt)
        fetch(`https://cdo2cdo.logdms.com/get_initial_data`, {
            headers: {
                'jwt': data.jwt
            }
        })
            .then(res => res.json())
            .then(res => {
                changeData(res);
                setLoading(false);
            }).catch(err => {
                console.log(err)
            })
        // eslint-disable-next-line
    }, [])

    const getObjectsWithIds = (sourceArray, idsArray) => {
        return sourceArray.filter(obj => idsArray.includes(obj.id)).slice(0, 3);
    };

    // Custom comparison function
    const alphabetically = (a, b) => {
        const valueA = a.label.toLowerCase(); // Convert to lowercase for case-insensitive sorting
        const valueB = b.label.toLowerCase();

        if (valueA < valueB) {
            return -1; // a should be sorted before b
        }
        if (valueA > valueB) {
            return 1; // a should be sorted after b
        }
        return 0; // a and b are equal
    };

    const onSelectChange = (e) => {
        setStore(e)
        changeDestinationCDOData(null)
        fetch(`https://cdo2cdo.logdms.com/get_cdo_data?cdoId=${e}`, {
            headers: {
                'jwt': data.jwt
            }
        })
            .then(res => res.json())
            .then(res => {
                changeDestinationCDOData(res.CDOData);
            })
    }

    const getCDOSOptions = () => {
        const toReturnOptions = []

        if (appData.CDOList && appData.CDOList.length)
            appData.CDOList.forEach(element => {
                toReturnOptions.push({ value: element.id, label: element.name })
            });
        else
            return null
        return toReturnOptions;
    }
    const getSourceCDOOptions = () => {
        const toReturnOptions = []
        if (appData.sourceCdo)
            appData.sourceCdo.fields.forEach(element => {
                // Check if value is already selected in another field and remove the 3 required fields
                //if((element.id === appData.sourceCdo.displayNameFieldId || element.id === appData.sourceCdo.uniqueCodeFieldId || element.id === appData.sourceCdo.emailAddressFieldId) ||fields.some(value => value.source.id === element.id))
                if (fields.some(value => value.source.id === element.id))
                    toReturnOptions.push({ value: element.id, label: element.name, disabled: true });
                else toReturnOptions.push({ value: element.id, label: element.name })
            });
        else
            return null;
        return toReturnOptions.sort(alphabetically);
    }
    const getDestinationCDOOptions = () => {
        const toReturnOptions = []
        if (destinationCDOData)
            destinationCDOData.fields.forEach(element => {
                // Check if value is already selected in another field and remove the 3 required fields
                //if((element.id === destinationCDOData.displayNameFieldId || element.id === destinationCDOData.uniqueCodeFieldId || element.id === destinationCDOData.emailAddressFieldId) || fields.some(value => value.destination.id === element.id))
                if (fields.some(value => value.destination.id === element.id))
                    toReturnOptions.push({ value: element.id, label: element.name, disabled: true });
                else toReturnOptions.push({ value: element.id, label: element.name, disabled: false });
            });
        else
            return null
        return toReturnOptions.sort(alphabetically);
    }

    const removeItemAtIndex = (index) => {
        const updatedItems = [...fields];
        updatedItems.splice(index, 1);
        setFields(updatedItems);
    };

    const updateValueAtIndex = (index, fieldToUpdate, value) => {
        setFields(prevItems => {
            const updatedItems = [...prevItems];
            const updatedItem = { ...updatedItems[index] };
            if (fieldToUpdate === 'source') {
                updatedItem[fieldToUpdate] = appData.sourceCdo.fields.find(x => x.id === value);
            }
            else updatedItem[fieldToUpdate] = destinationCDOData.fields.find(x => x.id === value);
            updatedItems[index] = updatedItem;
            return updatedItems;
        });
    };

    console.log(fields)

    const getRequiredFields = (source) => {
        const array = [];
        if (source.displayNameFieldId)
            array.push(source.displayNameFieldId);
        if (source.uniqueCodeFieldId)
            array.push(source.uniqueCodeFieldId);
        if (source.emailAddressFieldId)
            array.push(source.emailAddressFieldId);
        return array
    }

    const submitData = () => {
        setDisableBtn(true);
        const loadingMessage = message.loading("Loading...", 0);
        let sourceFields = {};
        let destinationFields = {};
        let internalNamesMappings={};

        //destinationMapping.forEach( el => { importDefinition[el.internalName] = `{{CustomObject[${store}].Field[${el.id}]}}`})
        //sourceMapping.forEach( el => { toSendData[el.internalName] = `{{CustomObject[${appData.sourceCdo.id}].Field[${el.id}]}}`})

        fields.forEach(el => {
            if (el.source.id !== null && el.destination.id !== null) {
                sourceFields[el.source.internalName] = `{{CustomObject[${appData.sourceCdo.id}].Field[${el.source.id}]}}`;
                destinationFields[el.destination.internalName] = `{{CustomObject[${store}].Field[${el.destination.id}]}}`;
                internalNamesMappings[el.destination.internalName]=el.source.internalName
            }
        })

        // console.log(toSendData, importDefinition)

        // loadingMessage();
        // setDisableBtn(false);

        // console.log({
        //     source:{
        //         id: appData.sourceCdo.id,
        //         fields: sourceFields,
        //     },
        //     destination:{
        //         id: store,
        //         fields: destinationFields,
        //         identifierFieldName: fields[0].destination.name
        //     },
        // })

        fetch(`https://cdo2cdo.logdms.com/cdo2cdo/setMapping`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'jwt': data.jwt
            },
            body: JSON.stringify({
                source: {
                    id: appData.sourceCdo.id,
                    fields: sourceFields,
                },
                destination: {
                    id: store,
                    fields: destinationFields,
                    identifierFieldName: fields[0].destination.internalName
                },
                internalNamesMappings
            })
        })
            .then(res => res.json())
            .then(res => {
                message.success({
                    content: `Mappings saved successfully!`,
                    className: "text-center",
                    duration: 2,
                });
            })
            .catch(() => {
                message.error({
                    content: `Something went wrong!`,
                    className: "text-center",
                    duration: 2,
                });
            })
            .finally(() => {
                loadingMessage();
                setDisableBtn(false);
            });
    }

    if (loading)
        return <div className="flex h-screen items-center justify-center bg-white">
            <div className="flex flex-col items-center justify-center gap-5">
                <div className="h-40 w-40 animate-spin rounded-full border-t-4 border-b-4 border-green-11"></div>
            </div>
        </div>

    return <div className="p-4">
        <div className="max-w-[1300px] mx-auto">
            <div className="h-10"></div>
            <h1 className="font-poppins text-2xl text-gray-1 font-bold text-center">
                Source CDO: <span className="text-green-11">{appData.sourceCdo ? appData.sourceCdo.name : "Loading"}</span>
            </h1>
            <div className="h-10"></div>
            <div className="relative">
                <Select
                    id="CDOStore"
                    allowClear
                    onClear={() => setStore(null)}
                    showSearch
                    options={getCDOSOptions()}
                    value={store}
                    onChange={(e) => onSelectChange(e)}
                    className="peer shadow w-full cursor-default appearance-none rounded-xl border-2 py-2 font-poppins leading-tight placeholder-transparent transition-colors duration-500 border-zinc-200 focus-within:border-green-11 hover:border-green-11 focus:border-green-11"
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    dropdownAlign={{ offset: [-2, 15] }}
                />
                <label
                    htmlFor="CDOStore"
                    className={`${store && store.length ? '-top-[9px] text-sm' : 'top-[0.75rem] text-lg'
                        } text-gray-1 absolute left-3 transform rounded-full bg-white px-1 font-poppins font-bold transition-all duration-500 peer-placeholder-shown:top-[0.75rem] peer-placeholder-shown:text-lg peer-focus-within:-top-[9px] peer-focus-within:text-sm pointer-events-none truncate`}
                >
                    CDO Store <span className="text-zinc-400 font-normal">where the records will be stored</span>
                </label>
            </div>
            <div className="h-10"></div>
            {appData.sourceCdo && destinationCDOData ?
                <div className="relative border-2 border-zinc-200 rounded-xl px-4 shadow py-6">
                    <div className="absolute inset-x-0 text-center flex justify-center -top-4">
                        <div className="px-2 bg-white">
                            <h1 className="font-poppins uppercase font-bold text-xl text-gray-1">
                                Mapping
                            </h1>
                        </div>
                    </div>
                    {/* <div className="flex flex-col gap-2 w-full justify-center max-w-xl mx-auto">
                    <p className="font-poppins text-lg text-gray-1 font-bold text-center">
                        Required Fields<span className="text-orange-1">*</span>
                    </p>
                    {[...appData.sourceCdo.fields.filter(field => field.id === appData.sourceCdo.displayNameFieldId || field.id === appData.sourceCdo.uniqueCodeFieldId || field.id === appData.sourceCdo.emailAddressFieldId)].map((elem, index)=>{
                        if(appData.sourceCdo.displayNameFieldId === elem.id){                          
                            return <div key={index} className="flex flex-col">
                                <p className="font-poppins text-base text-gray-1 font-bold">
                                    Display Name Field
                                </p>
                                <div className="flex flex-row gap-4 py-1 w-full">
                                    <div className={`flex flex-row w-full items-center border-2 rounded-xl ${appData.sourceCdo.displayNameFieldId === elem.id ? "border-orange-1" : "border-zinc-200"}`}>
                                        <p className="font-poppins text-base text-gray-1 px-4 py-1">{elem.name}</p>
                                    </div>
                                    <div className="my-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="25" height="25"><path d="M23.12,9.91,19.25,6a1,1,0,0,0-1.42,0h0a1,1,0,0,0,0,1.41L21.39,11H1a1,1,0,0,0-1,1H0a1,1,0,0,0,1,1H21.45l-3.62,3.61a1,1,0,0,0,0,1.42h0a1,1,0,0,0,1.42,0l3.87-3.88A3,3,0,0,0,23.12,9.91Z"/></svg>
                                    </div>
                                    <Select
                                        placeholder="please select"
                                        options={getDestinationCDOOptions()}
                                        dropdownAlign={{ offset: [-2, 15] }}
                                        disabled
                                        value={destinationCDOData.displayNameFieldId}
                                        className="peer w-full appearance-none rounded-xl border-2 py-2 font-poppins leading-tight placeholder-transparent transition-colors duration-500 border-zinc-200"
                                    />
                                </div>
                            </div>
                        }
                        if(appData.sourceCdo.uniqueCodeFieldId === elem.id){
                            return <div key={index} className="flex flex-col">
                                <p className="font-poppins text-base text-gray-1 font-bold">
                                    Unique Code Field
                                </p>
                                <div className="flex flex-row gap-4 py-1 w-full">
                                    <div className={`flex flex-row w-full items-center border-2 rounded-xl ${appData.sourceCdo.uniqueCodeFieldId === elem.id ? "border-orange-1" : "border-zinc-200"}`}>
                                        <p className="font-poppins text-base text-gray-1 px-4 py-1">{elem.name}</p>
                                    </div>
                                    <div className="my-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="25" height="25"><path d="M23.12,9.91,19.25,6a1,1,0,0,0-1.42,0h0a1,1,0,0,0,0,1.41L21.39,11H1a1,1,0,0,0-1,1H0a1,1,0,0,0,1,1H21.45l-3.62,3.61a1,1,0,0,0,0,1.42h0a1,1,0,0,0,1.42,0l3.87-3.88A3,3,0,0,0,23.12,9.91Z"/></svg>
                                    </div>
                                    <Select
                                        placeholder="please select"
                                        options={getDestinationCDOOptions()}
                                        dropdownAlign={{ offset: [-2, 15] }}
                                        disabled
                                        value={destinationCDOData.uniqueCodeFieldId}
                                        className="peer w-full appearance-none rounded-xl border-2 py-2 font-poppins leading-tight placeholder-transparent transition-colors duration-500 border-zinc-200"
                                    />
                                </div>
                            </div>
                        }
                        if(appData.sourceCdo.emailAddressFieldId === elem.id){
                            return <div key={index} className="flex flex-col">
                                <p className="font-poppins text-base text-gray-1 font-bold">
                                    Email Address Field
                                </p>
                                <div key={index} className="flex flex-row gap-4 py-1 w-full">
                                    <div className={`flex flex-row w-full items-center border-2 rounded-xl ${appData.sourceCdo.emailAddressFieldId === elem.id ? "border-orange-1" : "border-zinc-200"}`}>
                                        <p className="font-poppins text-base text-gray-1 px-4 py-1">{elem.name}</p>
                                    </div>
                                    <div className="my-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="25" height="25"><path d="M23.12,9.91,19.25,6a1,1,0,0,0-1.42,0h0a1,1,0,0,0,0,1.41L21.39,11H1a1,1,0,0,0-1,1H0a1,1,0,0,0,1,1H21.45l-3.62,3.61a1,1,0,0,0,0,1.42h0a1,1,0,0,0,1.42,0l3.87-3.88A3,3,0,0,0,23.12,9.91Z"/></svg>
                                    </div>
                                    <Select
                                        placeholder="please select"
                                        options={getDestinationCDOOptions()}
                                        dropdownAlign={{ offset: [-2, 15] }}
                                        disabled
                                        value={destinationCDOData.emailAddressFieldId}
                                        className="peer w-full appearance-none rounded-xl border-2 py-2 font-poppins leading-tight placeholder-transparent transition-colors duration-500 border-zinc-200"
                                    />
                                </div>
                            </div>
                        }
                    })}
                </div> */}
                    <div className="flex flex-col gap-2 w-full justify-center max-w-xl mx-auto pt-2">
                        <p className="font-poppins text-lg text-gray-1 font-bold text-center">
                            Optional Fields
                        </p>
                        {
                            fields.map((elem, index) => {
                                return <div key={index} className="flex flex-row gap-4 py-1 w-full group">
                                    <Select
                                        placeholder="Please select a source field"
                                        options={getSourceCDOOptions()}
                                        dropdownAlign={{ offset: [-2, 15] }}
                                        onChange={(e) => updateValueAtIndex(index, 'source', e)}
                                        value={fields[index].source.id}
                                        className="peer w-full cursor-default appearance-none rounded-xl border-2 py-2 font-poppins leading-tight placeholder-transparent transition-colors duration-500 border-zinc-200 focus-within:border-green-11 hover:border-green-11 focus:border-green-11"
                                    />
                                    <div className=" relative my-auto">
                                        <div className="absolute z-10">
                                            <button onClick={() => {
                                                removeItemAtIndex(index)
                                            }} className="opacity-0 group-hover:opacity-100 transition-all duration-500 -translate-y-5 group-hover:translate-y-0 hover:rotate-180">
                                                <svg className="h-7 w-7 fill-current text-orange-1" viewBox="0 0 512 512"><g><path d="M342.635,169.365c-12.493-12.501-32.754-12.507-45.255-0.014c-0.005,0.005-0.01,0.01-0.015,0.014L256,210.752   l-41.365-41.387c-12.501-12.501-32.769-12.501-45.269,0s-12.501,32.769,0,45.269L210.752,256l-41.387,41.365   c-12.501,12.501-12.501,32.769,0,45.269c12.501,12.501,32.769,12.501,45.269,0l0,0L256,301.248l41.365,41.387   c12.501,12.501,32.769,12.501,45.269,0c12.501-12.501,12.501-32.769,0-45.269L301.248,256l41.387-41.365   c12.501-12.493,12.507-32.754,0.014-45.255C342.644,169.375,342.64,169.37,342.635,169.365z" /><path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256C511.847,114.678,397.322,0.153,256,0z M256,448   c-106.039,0-192-85.961-192-192S149.961,64,256,64s192,85.961,192,192C447.882,361.99,361.99,447.882,256,448z" /></g></svg>
                                            </button>
                                        </div>
                                        <svg className="my-auto group-hover:opacity-0 transition-opacity duration-500" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="25" height="25"><path d="M23.12,9.91,19.25,6a1,1,0,0,0-1.42,0h0a1,1,0,0,0,0,1.41L21.39,11H1a1,1,0,0,0-1,1H0a1,1,0,0,0,1,1H21.45l-3.62,3.61a1,1,0,0,0,0,1.42h0a1,1,0,0,0,1.42,0l3.87-3.88A3,3,0,0,0,23.12,9.91Z" /></svg>
                                    </div>
                                    <Select
                                        placeholder="Please select a destination field"
                                        options={getDestinationCDOOptions()}
                                        dropdownAlign={{ offset: [-2, 15] }}
                                        onChange={(e) => updateValueAtIndex(index, 'destination', e)}
                                        value={fields[index].destination.id}
                                        className="peer w-full cursor-default appearance-none rounded-xl border-2 py-2 font-poppins leading-tight placeholder-transparent transition-colors duration-500 border-zinc-200 focus-within:border-green-11 hover:border-green-11 focus:border-green-11"
                                    />
                                </div>
                            })
                        }
                        <button onClick={() => setFields([...fields, {
                            source: { id: null },
                            destination: { id: null }
                        }])} className="flex flex-row items-center justify-center gap-2 text-green-11 enabled:hover:text-white bg-zinc-50 enabled:hover:bg-green-11 px-6 py-2 rounded-xl shadow border-2 border-green-11 transition-all duration-500 disabled:cursor-not-allowed disabled:opacity-60">
                            <p className="font-poppins text-lg font-bold uppercase">
                                Add Mapping
                            </p>
                        </button>
                    </div>
                </div> : null}
            {appData.sourceCdo && destinationCDOData ? <div>
                <div className="h-10"></div>
                <button
                    disabled={disableBtn}
                    onClick={() => submitData()}
                    type="button"
                    className="w-full max-w-xs mx-auto group flex gap-2 items-center justify-center py-2 border-2 border-green-11 bg-white enabled:hover:bg-green-11 text-green-11 enabled:hover:text-white rounded-xl shadow transition-all duration-500 disabled:opacity-60 disabled:cursor-not-allowed"
                >
                    <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24"><path d="M12,10a4,4,0,1,0,4,4A4,4,0,0,0,12,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,16Z" /><path d="M22.536,4.122,19.878,1.464A4.966,4.966,0,0,0,16.343,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7.657A4.966,4.966,0,0,0,22.536,4.122ZM17,2.08V3a3,3,0,0,1-3,3H10A3,3,0,0,1,7,3V2h9.343A2.953,2.953,0,0,1,17,2.08ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2V3a5.006,5.006,0,0,0,5,5h4a4.991,4.991,0,0,0,4.962-4.624l2.16,2.16A3.02,3.02,0,0,1,22,7.657Z" /></svg>
                    <p className="font-poppins text-lg font-bold uppercase">
                        Save
                    </p>
                </button>
                <div className="h-10"></div>
            </div> : null}
        </div>
    </div >
}

export default MainScreen