import Footer from "./Components/Footer";
import { useEffect, useState } from "react";
import MainScreen from "./MainScreen";

function App() {
	const [loading, changeLoading] = useState(true);
	const [error, setError] = useState("")
	const [toPassData, changeToPassData] = useState(null)
	const urlParams = new URLSearchParams(document.location.search);

	useEffect(() => {
		const JWT = urlParams.get("jwt");
		if (JWT) {
			changeToPassData({
				jwt: JWT
			})
			changeLoading(false)
		} else setError(true);
	}, [])

	function Error() {
		return (
			<div className="flex h-screen items-center justify-center bg-white">
				<div className="flex flex-col items-center justify-center gap-5 px-4">
					<h1 className="font-poppins text-3xl font-bold text-gray-1 max-w-lg text-center whitespace-pre-wrap">
						<span className='text-orange-1'>Wrong access token!</span> <br />
						Please try again later.
					</h1>
				</div>
			</div>
		)
	}

	function Loading() {
		return (
			<div className="flex h-screen items-center justify-center bg-white">
				<div className="flex flex-col items-center justify-center gap-5 px-4">
					<div className="h-36 w-36 animate-spin rounded-full border-y-4 border-green-11"></div>
					<h1 className="font-poppins text-3xl font-bold text-gray-1">Loading</h1>
				</div>
			</div>
		)
	}

	if (error)
		return <Error />

	if (loading)
		return <Loading />

	return (
		<>
			<MainScreen data={toPassData} />
			<Footer />
		</>
	);
}

export default App;
